const PASSWORD_PATTERN =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]*$/;
const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
const NAME_PATTERN = /^[a-zA-Z0-9ぁ-んァ-ン一-龥\sー]*$/;
const NUMBER_PATTERN = /^[0-9]\d*$/;
const PHONE_NUMBER_LENGTH_PATTERN = /^\d{10,11}$/;
const NEGATIVE_NUMBER_PATTERN = /^-?(?!0)\d*$/;
const POSTAL_CODE_PATTERN = /^\d{7,7}$/;
const CONTACT_NAME_PATTERN = /^[a-zA-Zぁ-んァ-ン一-龥\sー]*$/;
const CODE_PATTERN = /^\S*$/;

const DATE_PICKER_FORMAT = 'YYYY-MM-DD';
const DATE_PICKER_FORMAT_JP = 'YYYY年MM月DD日';
const DATE_PICKER_WITHOUT_DAY_FORMAT_JP = 'YYYY年MM月';
const DATE_PICKER_WITHOUT_DAY_AND_SHORT_MONTH_FORMAT_JP = 'YYYY年M月';
const DATE_PICKER_FULL_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_PICKER_FULL_FORMAT_JP = 'YYYY年MM月DD日 HH:mm:ss';
const DATE_PICKER_WITHOUT_SECOND_FORMAT = 'YYYY-MM-DD HH:mm';
const DATE_PICKER_WITHOUT_SECOND_FORMAT_JP = 'YYYY年MM月DD日 HH:mm';
const DATE_PICKER_FORMAT_SLASH_LONG = 'YYYY/MM/DD HH:mm:ss';
const DATE_PICKER_WITHOUT_TIME_FORMAT_SLASH_LONG = 'YYYY/MM/DD';
const DATE_PICKER_WITHOUT_SECOND_FORMAT_SLASH_SHORT = 'YYYY/M/D HH:mm';
const DATE_PICKER_WITHOUT_SECOND_FORMAT_SLASH_LONG = 'YYYY/MM/DD HH:mm';

export {
  NAME_PATTERN,
  CODE_PATTERN,
  EMAIL_PATTERN,
  NUMBER_PATTERN,
  PHONE_NUMBER_LENGTH_PATTERN,
  PASSWORD_PATTERN,
  CONTACT_NAME_PATTERN,
  POSTAL_CODE_PATTERN,
  DATE_PICKER_FORMAT,
  DATE_PICKER_FORMAT_JP,
  DATE_PICKER_FULL_FORMAT,
  DATE_PICKER_FULL_FORMAT_JP,
  DATE_PICKER_WITHOUT_SECOND_FORMAT,
  DATE_PICKER_WITHOUT_SECOND_FORMAT_JP,
  DATE_PICKER_WITHOUT_DAY_FORMAT_JP,
  DATE_PICKER_WITHOUT_DAY_AND_SHORT_MONTH_FORMAT_JP,
  DATE_PICKER_WITHOUT_SECOND_FORMAT_SLASH_SHORT,
  NEGATIVE_NUMBER_PATTERN,
  DATE_PICKER_FORMAT_SLASH_LONG,
  DATE_PICKER_WITHOUT_TIME_FORMAT_SLASH_LONG,
  DATE_PICKER_WITHOUT_SECOND_FORMAT_SLASH_LONG,
};
