import { LabelDataDef } from '@app/models/tagfont.types';

const LABEL_PREVIEW_MESSAGES = {
  overMaxLength: '文字数オーバーです。',
  notMatchRule: '半角英数字と記号のみ利用できます。',
} as const;

const LABEL_TAG_FONT_SIZE = {
  main_text: 38.5,
  location_1: 23.9,
  location_2: 23.9,
  location_detail_1: 9,
  location_detail_2: 9,
  address_1: 9.8,
  address_2: 9.8,
  content: 22.7,
  subtext_1: 16.1,
  subtext_2: 16.1,
  print_date: 9,
  print_date_day: 9,
  print_date_month: 9,
  print_date_start: 22,
  print_date_end: 22,
} as const;

const MAIN_TEXT_FONT_SIZE_MIN = 23 as const;

const LABEL_TAG_PRINT_FONT_SIZE = {
  main_text: 116,
  location_1: 72,
  location_2: 72,
  address_1: 29,
  address_2: 29,
  content: 68,
  subtext_1: 48,
  subtext_2: 48,
} as const;

const MAIN_TEXT_PRINT_FONT_SIZE_MIN = 70 as const;

const LABEL_TAG_PRINT_MAX_WIDTH = {
  main_text: 727,
  location_1: 609,
  location_2: 609,
  address_1: 609,
  address_2: 609,
  content: 609,
  subtext_1: 609,
  subtext_2: 609,
};

const LABEL_TAG_KERNING = {
  main_text: 'kern',
  location_1: 'kern10',
  location_2: 'kern10',
  location_detail_1: 'kern12',
  location_detail_2: 'kern12',
  address_1: 'kern10',
  address_2: 'kern10',
  content: 'kern10',
  subtext_1: 'kern10',
  subtext_2: 'kern10',
  print_date_start: 'kern15',
  print_date_end: 'kern15',
  print_date: 'kern12',
  print_date_month: 'kern12',
  print_date_day: 'kern12',
} as LabelDataDef;

const LABEL_TAG_PRINT_KERNING = {
  main_text: 1,
  location_1: 1.1,
  location_2: 1.1,
  address_1: 1.1,
  address_2: 1.1,
  content: 1.1,
  subtext_1: 1.1,
  subtext_2: 1.1,
};

const LABEL_INPUT_KEYS = [
  'print_date_month',
  'print_date_day',
  'main_text',
  'location_1',
  'location_2',
  'location_detail_1',
  'location_detail_2',
  'address_1',
  'address_2',
  'content',
  'subtext_1',
  'subtext_2',
  'print_date_start',
  'print_date_end',
];

const PRINT_DATE_DISPLAY_STATUS = {
  'not-specify': '0',
  specify: '1',
  period: '2',
};

const PRINT_DATE_BIG1_FORMAT = 'yyyy.MM.DD ddd.';
const PRINT_DATE_BIG1_FORMAT_PERIOD = 'yyyy.MM.DD ddd -';
const PRINT_DATE_FORMAT_MONTH = 'MMM.';
const PRINT_DATE_FORMAT_DAY = 'Do.';

export {
  LABEL_PREVIEW_MESSAGES,
  LABEL_TAG_FONT_SIZE,
  MAIN_TEXT_FONT_SIZE_MIN,
  LABEL_TAG_KERNING,
  LABEL_INPUT_KEYS,
  PRINT_DATE_BIG1_FORMAT,
  PRINT_DATE_BIG1_FORMAT_PERIOD,
  PRINT_DATE_FORMAT_MONTH,
  PRINT_DATE_FORMAT_DAY,
  LABEL_TAG_PRINT_FONT_SIZE,
  MAIN_TEXT_PRINT_FONT_SIZE_MIN,
  LABEL_TAG_PRINT_MAX_WIDTH,
  LABEL_TAG_PRINT_KERNING,
  PRINT_DATE_DISPLAY_STATUS,
};
