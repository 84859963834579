import { notification as AntdNotification } from 'antd';

type AntdNotificationOptionsDef = {
  type?: 'success' | 'error';
  message?: string;
  description: string;
};

const useAntdNotification = () => {
  const notification = ({
    type = 'success',
    message = '',
    description,
  }: AntdNotificationOptionsDef) =>
    AntdNotification[type]({ message, description });

  return notification;
};

export default useAntdNotification;
