import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import {
  PRINT_DATE_BIG1_FORMAT,
  PRINT_DATE_FORMAT_MONTH,
  PRINT_DATE_FORMAT_DAY,
  PRINT_DATE_DISPLAY_STATUS,
  PRINT_DATE_BIG1_FORMAT_PERIOD,
} from '@app/constants/tagfont.constants';
import {
  LabelInputFocusedDef,
  LabelDataDef,
  LabelInputDef,
} from '@app/models/tagfont.types';

type InitialLabelReducerDef = {
  labelData: LabelDataDef;
  labelInputFocused: LabelInputFocusedDef;
};

const initialState = {} as InitialLabelReducerDef;

export const labelSlice = createSlice({
  name: 'label',
  initialState,
  reducers: {
    setLabelValue(state, action) {
      const { payload } = action;
      const { labelData } = state;
      const printDate = payload?.print_date ?? labelData?.print_date;
      const printDateDisplay =
        payload?.print_date_display ?? labelData?.print_date_display;
      const isNotSpecifyPrintDateDisplay =
        printDateDisplay === PRINT_DATE_DISPLAY_STATUS['not-specify'];
      const isSpecifyPrintDateDisplay =
        printDateDisplay === PRINT_DATE_DISPLAY_STATUS.specify;
      const isPeriodPrintDateDisplay =
        printDateDisplay === PRINT_DATE_DISPLAY_STATUS.period;

      state.labelData = {
        ...labelData,
        ...(payload as Record<LabelInputDef, string>),
        ...(isNotSpecifyPrintDateDisplay ||
        !printDate ||
        !printDateDisplay ||
        (printDate?.length && isSpecifyPrintDateDisplay) ||
        (!printDate?.length && isPeriodPrintDateDisplay)
          ? {
              print_date_month: '',
              print_date_day: '',
              print_date_start: '',
              print_date_end: '',
            }
          : {
              print_date_month: moment(
                (isSpecifyPrintDateDisplay
                  ? printDate
                  : printDate?.[0]) as moment.Moment
              )
                .locale('en')
                .format(PRINT_DATE_FORMAT_MONTH)
                .toUpperCase(),
              print_date_day: moment(
                (isSpecifyPrintDateDisplay
                  ? printDate
                  : printDate?.[0]) as moment.Moment
              )
                .locale('en')
                .format(PRINT_DATE_FORMAT_DAY),
              print_date_start: moment(
                (isSpecifyPrintDateDisplay
                  ? printDate
                  : printDate?.[0]) as moment.Moment
              )
                .format(
                  isSpecifyPrintDateDisplay
                    ? PRINT_DATE_BIG1_FORMAT
                    : PRINT_DATE_BIG1_FORMAT_PERIOD
                )
                .toUpperCase(),
              print_date_end: printDate?.[1]
                ? moment(printDate?.[1] as moment.Moment)
                    .format(PRINT_DATE_BIG1_FORMAT)
                    .toUpperCase()
                : '',
            }),
      };
    },
    clearLabelValue(state) {
      state.labelData = {};
    },
    setLabelFocus(state, action) {
      const { payload } = action;
      const { labelInputFocused } = state;
      state.labelInputFocused = {
        ...labelInputFocused,
        ...(payload as Record<LabelInputDef, boolean>),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLabelValue, clearLabelValue, setLabelFocus } =
  labelSlice.actions;

export default labelSlice.reducer;
