import { ReactComponent as IconChecked } from '@app/assets/icons/icon-checked.svg';
import { ReactComponent as IconCloseCircle } from '@app/assets/icons/icon-circle-close.svg';
import { ReactComponent as IconIssue } from '@app/assets/icons/icon-issue.svg';

const ACCOUNT_REGISTRATION_STATUS_KEY = {
  registrationComplete: 'registration-complete',
  duringAnInvitation: 'during-an-invitation',
  invitationExpired: 'invitation-expired',
} as const;

const ACCOUNT_REGISTRATION_STATUS_CONTENTS: Record<
  string,
  { status: string; icon: JSX.Element }
> = {
  [ACCOUNT_REGISTRATION_STATUS_KEY.registrationComplete]: {
    status: '登録完了',
    icon: <IconChecked className="mr-4" />,
  },
  [ACCOUNT_REGISTRATION_STATUS_KEY.duringAnInvitation]: {
    status: '招待中',
    icon: <IconIssue className="mr-4" />,
  },
  [ACCOUNT_REGISTRATION_STATUS_KEY.invitationExpired]: {
    status: '招待期限切れ',
    icon: <IconCloseCircle className="mr-4" />,
  },
};

export {
  ACCOUNT_REGISTRATION_STATUS_KEY,
  ACCOUNT_REGISTRATION_STATUS_CONTENTS,
};
