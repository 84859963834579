const ROUTES_PATH = {
  login: '/login',
  top: '/',
  changePassword: '/change-password',
  // banner
  bannerManagement: '/banner-management',
  bannerNew: '/banner-management/new',
  bannerEdit: '/banner-management/:id',
  // organization
  organizationManagement: '/organization-management',
  organizationNew: '/organization-management/new',
  organizationEdit: '/organization-management/:id',
  // shop
  shopManagement: '/shop-management',
  shopBusinessApplication: '/shop-management/:id/business-application-form',
  shopNew: '/shop-management/new',
  shopWeddingNew: '/shop-management/new-wedding',
  shopEdit: '/shop-management/:id',
  // drink
  drinkManagement: '/drink-management',
  drinkNew: '/drink-management/new',
  drinkEdit: '/drink-management/:id',
  // shop drink
  shopDrinkManagement: '/shop-drink-management',
  // label
  labelManagement: '/label-management',
  labelNew: '/label-management/new',
  labelDetail: 'label-management/:id',
  // label order
  labelOrderManagement: '/label-order-management',
  // account management
  accountManagement: '/account-management',
  accountNew: '/account-management/new',
  accountDetail: '/account-management/:id',
  // password registration
  passwordRegistration: '/password-registration',
  passwordRegistrationExpiredError: '/password-registration/expired-error',
  passwordRegistrationEmailError: '/password-registration/email-error',
  passwordRegistrationSuccess: '/password-registration/success',
  passwordRegistrationError: '/password-registration/error',
  // password reset
  passwordResetSendEmail: '/password-reset/send-email',
  passwordResetSendEmailSuccess: '/password-reset/send-email-success',
  passwordResetInputPassword: '/password-reset/new-password',
  passwordResetExpiredError: '/password-reset/expired-error',
  passwordResetSuccess: '/password-reset/success',
  passwordResetError: '/password-reset/error',
  // label template
  labelTemplateManagement: '/label-template-management',
  labelTemplateDetail: '/label-template-management/:id',
  // sales results application status - master
  salesResultsApplicationStatus: '/sales-results-application-status',
  salesResultsApplicationStatusDetail: '/sales-results-application-status/:id',
  // amount adjustment
  salesResultsApplicationStatusAmountAdjustmentDetails: (id?: unknown) =>
    `/sales-results-application-status/${
      id || ':id'
    }/amount-adjustment-details`,
  salesResultsApplicationStatusAmountAdjustmentForm: (id?: unknown) =>
    `/sales-results-application-status/${id || ':id'}/amount-adjustment`,
  salesResultsApplicationAmountAdjustmentDetails: (id?: unknown) =>
    `/sales-results-application/${id || ':id'}/amount-adjustment-details`,
  // sales results application - chain
  salesResultsApplication: '/sales-results-application',
  salesResultsApplicationDetail: '/sales-results-application/:id',
  // sales performance history
  salesPerformanceHistoryManagement: '/sales-performance-history-management',
  // billing management - master
  billingManagement: '/billing-management',
  billingDetail: '/billing-management/:id',
  // payment info management - chain
  paymentInformationManagement: '/payment-information-management',
  // usage guide
  usageGuide: '/usage-guide',
  // error
  forbidden: '/403',
  notfound: '/404',
  //label original
  originalLabelManagement: '/snap-label-management',
  originalLabelDetail: '/snap-label-management/:id',
  // full custom label
  labelFullCustomManagement: '/full-custom-label-management',
  labelFullCustomNew: '/full-custom-label-management/new',
  labelFullCustomDetail: '/full-custom-label-management/:id',
  faq: '/faq',
} as const;

export { ROUTES_PATH };
