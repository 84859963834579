import {
  FORM_VALIDATION_RULES,
  PASSWORD_PATTERN,
  EMAIL_PATTERN,
} from '@app/constants';
import { PasswordContentDef } from '@app/models/password.types';

const PASSWORD_SCREEN = {
  registration: 'registration',
  reset: 'reset',
  sendEmail: 'sendEmail',
} as const;

const PASSWORD_VALIDATES = [
  FORM_VALIDATION_RULES.pattern(
    PASSWORD_PATTERN,
    '英大文字小文字、数字の組み合わせで8桁以上で設定してください。'
  ),
  FORM_VALIDATION_RULES.whitespace('パスワードを入力してください。'),
  FORM_VALIDATION_RULES.required('パスワードを入力してください。'),
  FORM_VALIDATION_RULES.min(8, 'パスワードは8文字以上で入力してください。'),
  FORM_VALIDATION_RULES.max(99, 'パスワードは99文字以下で入力してください。'),
];

const EMAIL_VALIDATES = [
  FORM_VALIDATION_RULES.pattern(
    EMAIL_PATTERN,
    'メールアドレスの形式で入力してください。'
  ),
  FORM_VALIDATION_RULES.whitespace('メールアドレスを入力してください。'),
  FORM_VALIDATION_RULES.required('メールアドレスを入力してください。'),
  FORM_VALIDATION_RULES.max(
    128,
    'メールアドレスは128文字以下で入力してください。'
  ),
];

const PASSWORD_SCREENS_CONTENTS: Record<
  keyof typeof PASSWORD_SCREEN,
  PasswordContentDef
> = {
  registration: {
    title: 'パスワード登録',
    text: 'サービス管理システムのパスワードを設定し、登録ボタンを押してください。\nパスワードは英大文字小文字、数字の組み合わせで、8文字以上で登録してください。',
    label1: 'パスワード',
    name1: 'password',
    label2: 'パスワード（確認用）',
    name2: 'password-confirmation',
    btnText: '登録',
    validateRules: PASSWORD_VALIDATES,
    errorMsg: '確認用パスワードが一致しません。',
  },
  reset: {
    title: 'パスワード再設定',
    text: '新しいパスワードを入力してください。\nパスワードは英大文字小文字、数字の組み合わせで8桁以上で設定してください。',
    label1: '新しいパスワード',
    name1: 'password',
    label2: '新しいパスワード（確認用）',
    name2: 'password-confirmation',
    btnText: '設定',
    validateRules: PASSWORD_VALIDATES,
    errorMsg: '確認用パスワードが一致しません。',
  },
  sendEmail: {
    title: 'パスワードをリセット',
    text: '「パスワード再設定用のURL」を登録済みのメールアドレスに送信します。\n登録済みのメールアドレスを入力してください。',
    label1: '登録済みのメールアドレス',
    name1: 'email',
    label2: '',
    name2: '',
    btnText: 'メール送信',
    validateRules: EMAIL_VALIDATES,
    errorMsg: '',
  },
};

export { PASSWORD_SCREEN, PASSWORD_SCREENS_CONTENTS };
