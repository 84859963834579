import { rest } from 'msw';

import { OrganizationItemDef } from '@app/models/organization.types';

import organization from './organization.json';

export const organizationListGetHandler = rest.get<OrganizationItemDef>(
  `${import.meta.env.VITE_API_HOST}organization`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(organization));
  }
);

export default organizationListGetHandler;
