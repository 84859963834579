import { rest } from 'msw';

import { DrinkItemDef } from '@app/models/drink.types';

import drink from './drink.json';

export const drinkListGetHandler = rest.get<DrinkItemDef>(
  `${import.meta.env.VITE_API_HOST}drink`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(drink));
  }
);

export default drinkListGetHandler;
