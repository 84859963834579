import { Link } from 'react-router-dom';

import { ROUTES_PATH } from './routes-path.constants';

/* eslint-disable no-irregular-whitespace */
const FREQUENTLY_ASKED_QUESTIONS_CONTENTS = [
  {
    id: 'printer-connection',
    name: 'プリンタ接続',
    faqs: [
      {
        question:
          'Android/iOS端末とプリンタが接続ができない（接続に時間がかかってしまう）。',
        answer: (
          <>
            以下の手順を実行ください。
            <br />
            1. プリンタの再起動を行ってください。
            <br />
            2.
            1で問題が解消されない場合には、プリンタの接続診断を印刷してください。
            <br />
            　2-1.
            接続診断の印刷方法は、プリンター本体パネルよりメニュー画面に遷移します。
            <br />
            　2-2. 本体設定を選択し、本体設定画面に遷移します。
            <br />
            　2-3. ネットワーク設定を選択し、ネットワーク設定画面に遷移します。
            <br />
            　2-4. 接続診断を選択し、接続診断画面に遷移します。
            <br />
            　2-5.
            接続診断後、接続診断結果を印刷するを選択し、接続診断結果を印刷します。
            <br />
            3.
            診断結果がOKかつ問題が解消されない場合には、営業担当者へ接続診断結果をお送りください。
            <br />
            4.
            診断結果がNGの場合には、通信環境の再起動（ルーターの再起動等）を実施してください。
            <br />
            詳細は
            <Link to={ROUTES_PATH.usageGuide} className="inline">
              ネットワーク診断結果出力手順.pdf
            </Link>
            をご確認ください。
          </>
        ),
      },
      {
        question: 'テザリング接続では印刷できるが、Wi-Fi接続では印刷できない。',
        answer: `現在お使いのWi-Fiの設定でセパレート機能がONになっていないかをご確認ください。
          ONの場合はOFFへ切り替えをお願いいたします。
          そちらの対応を行なった場合にも解決しない場合には、お手数ですが営業担当者へご連絡ください。`,
      },
    ],
  },
  {
    id: 'sale-performance',
    name: '販売実績',
    faqs: [
      {
        question:
          '証憑をアップロードしても実績申請ボタンが押せず、販売実績登録ができない。',
        answer: `アップロードに失敗している可能性があります。お手数ですが、証憑の再アップロードをお願いいたします。
          それでも解決しない場合、macOS / Windows OS / ご利用のブラウザが古いためにアップロードに失敗している可能性がございます。
          OS/ブラウザのアップデートを実施いただいた上で再度アップロード等を行っていただきますようお願いいたします。
          もし、OS/ブラウザが最新の場合や、OS/ブラウザのアップデート実施後も解消されない場合は下記情報と共に営業担当者へご連絡ください。
          ・チェーン名
          ・店舗名`,
      },
      {
        question:
          'イベント店舗であるにも関わらず、販売実績登録依頼メールが届いてしまう。',
        answer: `登録されている情報が誤っている可能性があります。
          確認いたしますので、以下の情報と合わせて営業担当者へご連絡ください。
          ・チェーン名
          ・店舗名`,
      },
      {
        question: '契約種類（常設店舗/イベント店舗）を変更したい。',
        answer: `変更のご依頼を、以下の情報と合わせて営業担当者へご連絡ください。
          ・チェーン名
          ・店舗名`,
      },
    ],
  },
  {
    id: 'register-and-edit-label',
    name: 'ラベル印刷・編集',
    faqs: [
      {
        question: 'ラベルの印刷ができない。',
        answer: (
          <>
            印刷アプリ側のプリンター接続状態が正常になっているかをご確認ください。
            <br />
            印刷アプリ側のプリンター接続状態確認方法 <br />
            1. 印刷アプリの「設定とサポート」を開く。
            <br />
            2.「プリンター設定」を押す。
            <br />
            3.「ステータス」に「正常」と書かれていることを確認。
            <br />
            もし「正常」と書かれていない場合には
            <Link to={ROUTES_PATH.usageGuide} className="inline">
              使い方ガイド.pdf
            </Link>
            の「症状3.
            印刷アプリでプリンターを検索しても表示されない」の確認項目をご確認ください。
          </>
        ),
      },
      {
        question:
          '画像登録時に「オリジナル画像URLが必須です」というエラーが表示される。',
        answer: `古いsafariバージョンをご利用いただいている場合、エラーが表示される可能性がございます。
          最新バージョン（15.4以上）へアップデート後に、画像を再度登録してください。`,
      },
    ],
  },
  {
    id: 'printing-app',
    name: '印刷アプリ',
    faqs: [
      {
        question:
          '印刷アプリのラベル選択画面でドリンクと印刷枚数が選択できない。',
        answer: (
          <>
            サービス管理画面のメニューの販売商品管理をご確認ください。
            <br />
            こちらで「販売中」となっていない商品は、印刷アプリに表示されません。
            <br />
            販売期間の修正または設定をお願いします。
            <br />
            設定方法は
            <Link to={ROUTES_PATH.usageGuide} className="inline">
              使い方ガイド.pdf
            </Link>
            の「2.1. 新しい店舗を開店するための準備をする」の「4.
            販売商品の確認」をご確認ください。
          </>
        ),
      },
      {
        question:
          '印刷アプリをインストールするための推奨タブレット（プリンタ接続用）が知りたい。',
        answer: (
          <>
            Androidタブレット（LAVIE TAB08/H04）
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.nec-lavie.jp/navigate/products2/pc/232q/05/lavie/t08/spec/index02.html"
            >
              https://www.nec-lavie.jp/navigate/products2/pc/232q/05/lavie/t08/spec/index02.html
            </a>
            iPad（第10世代）
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.apple.com/ja-jp/111840"
            >
              https://support.apple.com/ja-jp/111840
            </a>
            <br />
            上記が推奨端末になります。
          </>
        ),
      },
      {
        question: '印刷用アプリはAndroidタブレット端末以外でも使用できますか。',
        answer:
          'Androidスマホ/タブレット(AndroidOS15以上)、iOSスマホ（iOS15以上）、iPad端末（iPadOS15以上）でもご利用いただけます。',
      },
    ],
  },
  {
    id: 'snapdrink',
    name: 'スナップドリンク',
    faqs: [
      {
        question:
          'SNAP DRINKでお客様が注文カード（購入用QRコード）を発行できない。',
        answer: `通信の問題により、画像アップロードに時間がかかるもしくはタイムアウトになってしまっている可能性がございます。
          通信環境をご確認いただくかWi-Fiに接続いただき、最初からラベル作成をお試しいただくようお伝えください。`,
      },
    ],
  },
] as const;

export { FREQUENTLY_ASKED_QUESTIONS_CONTENTS };
