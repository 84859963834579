import { captureException } from '@sentry/react';
import { Auth } from 'aws-amplify';
import CookiesJs from 'js-cookie';

import { Cookies, CookieNames, CookiesInfoDef } from '@app/models/auth.types';

const getAccessToken = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return accessToken;
  } catch (error) {
    captureException(error);
  }
};

const extractAndParseCookies = (
  cookieCompatibility: 'amplify' | 'elasticsearch' = 'amplify'
): CookiesInfoDef => {
  const cookies = CookiesJs.get();
  if (!cookies) {
    return {} as CookiesInfoDef;
  }

  let cookieNames: CookieNames;
  if (cookieCompatibility === 'amplify') {
    cookieNames = getAmplifyCookieNames(cookies);
  } else {
    cookieNames = getElasticsearchCookieNames();
  }

  const result = {
    userData: cookies[cookieNames.userDataKey],
    tokenUserName: cookies[cookieNames.lastUserKey],
    idToken: cookies[cookieNames.idTokenKey],
    accessToken: cookies[cookieNames.accessTokenKey],
    refreshToken: cookies[cookieNames.refreshTokenKey],
  };

  return result;
};

const getElasticsearchCookieNames = () => {
  return {
    idTokenKey: 'ID-TOKEN',
    accessTokenKey: 'ACCESS-TOKEN',
    refreshTokenKey: 'REFRESH-TOKEN',
    cognitoEnabledKey: 'COGNITO-ENABLED',
  };
};

const getAmplifyCookieNames = (cookiesOrUserName: Cookies | string) => {
  const keyPrefix = `CognitoIdentityServiceProvider.${
    import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID
  }`;
  const lastUserKey = `${keyPrefix}.LastAuthUser`;
  let tokenUserName: string;
  if (typeof cookiesOrUserName === 'string') {
    tokenUserName = cookiesOrUserName;
  } else {
    tokenUserName = cookiesOrUserName[lastUserKey];
  }

  const result = {
    lastUserKey,
    userDataKey: `${keyPrefix}.${tokenUserName}.userData`,
    idTokenKey: `${keyPrefix}.${tokenUserName}.idToken`,
    accessTokenKey: `${keyPrefix}.${tokenUserName}.accessToken`,
    refreshTokenKey: `${keyPrefix}.${tokenUserName}.refreshToken`,
  };

  return result;
};

export { getAccessToken, extractAndParseCookies };
