import { SYSTEM_PERMISSIONS } from '@app/constants';
import { extractAndParseCookies } from '@app/helpers';
import { PermissionDef } from '@app/models/common.types';

const useUserInformation = () => {
  const userData = JSON.parse(
    (extractAndParseCookies()?.userData as string) || '{}'
  );
  const permission = userData?.UserAttributes?.find(
    (item: Record<string, unknown>) => item?.Name === 'custom:admin_type'
  )?.Value as PermissionDef;
  const sub = userData?.UserAttributes?.find(
    (item: Record<string, unknown>) => item?.Name === 'sub'
  )?.Value;
  const userName = userData?.Username;

  const isAdminAccount = permission === SYSTEM_PERMISSIONS.master;
  const isOrganizationAccount = permission === SYSTEM_PERMISSIONS.organization;
  const isStoreAccount = permission === SYSTEM_PERMISSIONS.shop;
  const isWeddingAccount = permission === SYSTEM_PERMISSIONS.wedding;

  return {
    sub,
    userName,
    permission,
    isAdminAccount,
    isOrganizationAccount,
    isStoreAccount,
    isWeddingAccount,
  };
};

export default useUserInformation;
