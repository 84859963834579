import 'antd/dist/antd.less';
import './styles/styles.scss';
import { lazy, Suspense, useEffect } from 'react';

import { Modal } from 'antd';
import { Amplify, Hub } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

import awsConfig from '../aws-exports';
import { AxiosInterceptor } from './api/api';
import { useAppDispatch } from './app/hooks';
import { SuspenseFallback } from './components/app';
import { setIsFormEdited } from './features/common/common-slice';

Amplify.configure(awsConfig);

// Routes are lazy loaded so they will access to correct permissions
const AppRoutes = lazy(() => import('@app/App.routes'));

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'signIn_failure') {
        // perform signIn fail
      }
    });
  }, []);

  useEffect(() => {
    Modal.destroyAll();
    dispatch(setIsFormEdited(false));
  }, [location]);

  return (
    <div className="app">
      <Suspense fallback={<SuspenseFallback />}>
        <AxiosInterceptor />
        <AppRoutes />
      </Suspense>
    </div>
  );
}

export default App;
