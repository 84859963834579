import { useEffect } from 'react';

import 'moment-timezone';
import {
  Debug as DebugIntegration,
  Offline as OfflineIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, notification } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP.js';
import moment from 'moment';
import { RestHandler, setupWorker } from 'msw';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
} from 'react-router-dom';

import { allHandlers } from '@mocks/handlers';

import App from './App';
import './index.css';
import { store } from './app/store';

// config moment month text
moment.locale('ja_JP', {
  monthsShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdaysMin: ['日', '月', '火', '水', '木', '金', '土'],
});
// config moment default timezone
moment.tz.setDefault('Asia/Tokyo');

// config notification
notification.config({ placement: 'top' });

// config React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});

// config MSW
if (import.meta.env.VITE_LOCAL_MOCKS === 'true') {
  const workerHandlers: RestHandler[] = allHandlers;
  setupWorker(...workerHandlers).start({ onUnhandledRequest: 'bypass' });
}

// config Sentry
const sentryTracingOrigins = [];
if (!import.meta.env.PROD) {
  sentryTracingOrigins.push('localhost', /^\//);
}
sentryTracingOrigins.unshift(import.meta.env.VITE_APP_URL);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new OfflineIntegration({ maxStoredEvents: 10 }),
    new ExtraErrorDataIntegration(),
    new DebugIntegration({ stringify: true }),
    new ReportingObserverIntegration(),
    new BrowserTracing({
      tracingOrigins: sentryTracingOrigins,
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  sampleRate: 1,
  tracesSampleRate: parseFloat(
    import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE
  ),
  attachStacktrace: true,
  debug: !import.meta.env.PROD,
  normalizeDepth: 10,
  initialScope: {
    tags: { system: 'Olb Admin System' },
  },
  release: `LLD-Admin_${moment().format('YYYY-MM-DD')}`,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          locale={jaJP}
          autoInsertSpaceInButton={false}
          pagination={{ showSizeChanger: false }}
        >
          <App />
        </ConfigProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);
