import { rest } from 'msw';

import { LabelItemDef } from '@app/models/label.types';

import label from './label.json';

export const labelListGetHandler = rest.get<LabelItemDef>(
  `${import.meta.env.VITE_API_HOST}labels`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(label));
  }
);

export default labelListGetHandler;
