import { createSlice } from '@reduxjs/toolkit';

import { AuthInitialStateDef } from '@app/models/auth.types';

export const initialState: AuthInitialStateDef = {
  auth: null,
};

export const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    clearAuth: (state) => {
      state.auth = initialState.auth;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, clearAuth } = authDataSlice.actions;

export default authDataSlice.reducer;
