import { bannerListGetHandler } from './api/banner/banner';
import { drinkListGetHandler } from './api/drink/drink';
import labelListGetHandler from './api/label/label';
import { organizationListGetHandler } from './api/organization/organization';
import { shopListGetHandler } from './api/shop/shop';

// List of api mocks to always load
export const handlers = [];

// List of api mocks to only load if the LOCAL_MOCKS env is true
export const localHandlers = [
  bannerListGetHandler,
  organizationListGetHandler,
  shopListGetHandler,
  drinkListGetHandler,
  drinkListGetHandler,
  labelListGetHandler,
];

export const allHandlers = [...handlers, ...localHandlers];

export default handlers;
