import { useEffect, useState } from 'react';

import { Modal } from 'antd';
import { Auth } from 'aws-amplify';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  CanceledError,
} from 'axios';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  API_ERROR_MESSAGES,
  API_STATUS_CODE,
  ROUTES_PATH,
} from '@app/constants';
import { getAccessToken } from '@app/helpers';
import { useAntdNotification, useUserInformation } from '@app/hooks';

export type CustomAxiosRequestConfig = AxiosRequestConfig & {
  isUploadS3?: boolean;
  isDownload?: boolean;
  isSelfHandleError?: boolean;
};

const api = axios.create({
  timeout: 30000,
  withCredentials: true,
  baseURL: import.meta.env.VITE_API_HOST,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env.VITE_API_KEY,
    'Accept-version': import.meta.env.VITE_API_VERSION,
  },
  paramsSerializer: {
    serialize: ({ isFromLogin: _, ...rest }) =>
      qs.stringify(rest, {
        skipNull: true,
        arrayFormat: 'comma',
        skipEmptyString: true,
      }),
  },
});

const AxiosInterceptor = () => {
  const navigate = useNavigate();
  const notification = useAntdNotification();
  const { isAdminAccount } = useUserInformation();
  const [abortController, setAbortController] = useState(new AbortController());
  const { pathname, search, state } = useLocation();

  useEffect(() => {
    const reqInterceptor = async (config: CustomAxiosRequestConfig) => {
      const accessToken = await getAccessToken();
      const { headers, isUploadS3, isDownload } = config;
      if (headers) {
        if (isUploadS3) {
          config.baseURL = '';
          (headers as AxiosHeaders).set(
            'Content-Type',
            'application/x-www-form-urlencoded'
          );
        } else {
          !isDownload &&
            (headers as AxiosHeaders).set(
              'Authorization',
              `Bearer ${accessToken}`
            );
        }
      }
      config.signal = abortController.signal;
      return config;
    };

    const reqErrInterceptor = (error: AxiosError) => {
      return Promise.reject(error);
    };

    const resInterceptor = (response: AxiosResponse) => {
      return response;
    };

    const resErrInterceptor = async (error: AxiosError) => {
      const config = error.config as CustomAxiosRequestConfig;
      const isDownload = config?.isDownload;
      const errMethod = config?.method;
      const isSelfHandleError = config?.isSelfHandleError;
      const errStatus = isDownload
        ? API_STATUS_CODE.downloadFail
        : error?.response?.status || error?.code;
      const errMessage = (error?.response?.data as Record<string, string>)
        ?.message;
      const errorContent = window.navigator.onLine
        ? API_ERROR_MESSAGES[errStatus as number] || errMessage
        : API_ERROR_MESSAGES.noInternet;
      const loginRedirectUrl = `${ROUTES_PATH.login}?redirect_url=${pathname}${search}`;

      if (isSelfHandleError) return Promise.reject(error);

      abortController.abort();
      setAbortController(new AbortController());

      switch (errStatus) {
        case API_STATUS_CODE.outdated:
          Modal.error({
            centered: true,
            title: '操作に失敗しました',
            content: API_ERROR_MESSAGES[errStatus],
            okText: '再読み込み',
            onOk() {
              window.location.reload();
            },
          });
          break;
        case API_STATUS_CODE.unauthorized:
          Modal.error({
            centered: true,
            title: '操作に失敗しました',
            content: API_ERROR_MESSAGES[errStatus],
            onOk: async () => {
              await Auth.signOut();
              navigate(loginRedirectUrl);
            },
          });
          break;
        case API_STATUS_CODE.notFound:
          errMethod === 'get'
            ? Modal.error({
                centered: true,
                title: '操作に失敗しました',
                content: API_ERROR_MESSAGES[errStatus],
                onOk() {
                  state?.isFromLogin
                    ? navigate(
                        isAdminAccount
                          ? ROUTES_PATH.organizationManagement
                          : ROUTES_PATH.shopManagement
                      )
                    : navigate(-1);
                },
              })
            : notification({
                type: 'error',
                message: '操作に失敗しました',
                description: errorContent,
              });
          break;
        case API_STATUS_CODE.movedPermanently:
          Modal.error({
            centered: true,
            title: '操作に失敗しました',
            content: errMessage,
            onOk: async () => {
              await Auth.signOut();
              navigate(loginRedirectUrl);
            },
          });
          break;
        default:
          !(error instanceof CanceledError) &&
            notification({
              type: 'error',
              message: '操作に失敗しました',
              description: errorContent,
            });
          break;
      }
      return Promise.reject(error);
    };

    const reqInterceptors = api.interceptors.request.use(
      reqInterceptor,
      reqErrInterceptor
    );

    const resInterceptors = api.interceptors.response.use(
      resInterceptor,
      resErrInterceptor
    );

    return () => {
      api.interceptors.request.eject(reqInterceptors);
      api.interceptors.response.eject(resInterceptors);
    };
  }, [pathname, search, abortController]);

  return null;
};

export { AxiosInterceptor };

export default api;
