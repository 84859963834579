import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

import authDataReducer from '@app/features/authData/auth-data-slice';
import commonReducer from '@app/features/common/common-slice';
import labelReducer from '@app/features/label/label-slice';

export const store = configureStore({
  reducer: {
    authData: authDataReducer,
    common: commonReducer,
    label: labelReducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['common/setScrollTop', 'label/setLabelValue'],
        ignoredPaths: ['common.scrollTop', 'label.labelData.print_date'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
