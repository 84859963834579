import UsageGuideThumbnail1Image from '@app/assets/images/usage-guide/usage-guide-thumbnail-1.png';
import UsageGuideThumbnail2Image from '@app/assets/images/usage-guide/usage-guide-thumbnail-2.png';
import UsageGuideThumbnail3Image from '@app/assets/images/usage-guide/usage-guide-thumbnail-3.png';
import UsageGuideThumbnail4Image from '@app/assets/images/usage-guide/usage-guide-thumbnail-4.png';
import UsageGuideThumbnail5Image from '@app/assets/images/usage-guide/usage-guide-thumbnail-5.png';
import { PermissionDef, UsageGuideItemDef } from '@app/models/common.types';

import { SYSTEM_PERMISSIONS } from './auth.constants';

const USAGE_GUIDE_BY_PERMISSIONS = {
  [SYSTEM_PERMISSIONS.master]: {
    url: `${import.meta.env.VITE_APP_URL}/usage-guides/master_usage_guide.pdf`,
    versionUrl: `${
      import.meta.env.VITE_APP_URL
    }/version-usage-guides/master_version.txt`,
  },
  [SYSTEM_PERMISSIONS.organization]: {
    url: `${import.meta.env.VITE_APP_URL}/usage-guides/chain_usage_guide.pdf`,
    versionUrl: `${
      import.meta.env.VITE_APP_URL
    }/version-usage-guides/chain_version.txt`,
  },
  [SYSTEM_PERMISSIONS.shop]: {
    url: `${import.meta.env.VITE_APP_URL}/usage-guides/store_usage_guide.pdf`,
    versionUrl: `${
      import.meta.env.VITE_APP_URL
    }/version-usage-guides/store_version.txt`,
  },
  [SYSTEM_PERMISSIONS.wedding]: {
    url: `${
      import.meta.env.VITE_APP_URL
    }/usage-guides/planning_usage_guide.pdf`,
    versionUrl: `${
      import.meta.env.VITE_APP_URL
    }/version-usage-guides/planning_version.txt`,
  },
} as Record<PermissionDef, UsageGuideItemDef>;

const USAGE_GUIDE_VIDEOS = [
  {
    name: 'ラベルの作成',
    url: `${import.meta.env.VITE_VIDEO_URL}/trainings/create_label.mp4`,
    thumbnail: UsageGuideThumbnail1Image,
  },
  {
    name: 'プリンターのメンテナンス',
    url: `${import.meta.env.VITE_VIDEO_URL}/trainings/printer_maintenance.mp4`,
    thumbnail: UsageGuideThumbnail2Image,
  },
  {
    name: 'プリンターの接続・印刷',
    url: `${import.meta.env.VITE_VIDEO_URL}/trainings/printer_connection.mp4`,
    thumbnail: UsageGuideThumbnail3Image,
  },
  {
    name: 'スナップドリンクの利用',
    url: `${import.meta.env.VITE_VIDEO_URL}/trainings/snap_drink.mp4`,
    thumbnail: UsageGuideThumbnail4Image,
  },
  {
    name: '販売実績の登録',
    url: `${
      import.meta.env.VITE_VIDEO_URL
    }/trainings/sale_record_registration.mp4`,
    thumbnail: UsageGuideThumbnail5Image,
  },
] as const;

export { USAGE_GUIDE_VIDEOS, USAGE_GUIDE_BY_PERMISSIONS };
