import { createSlice } from '@reduxjs/toolkit';

import { CommonInitialStateDef } from '@app/models/common.types';

export const initialState: CommonInitialStateDef = {
  isProcessing: false,
  scrollTop: null,
  contentScrollTop: null,
  isFormEdited: false,
  backupOrganizationId: null,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsProcessing: (state, action) => {
      state.isProcessing = action.payload;
    },
    setScrollTop: (state, action) => {
      state.scrollTop = action.payload;
    },
    setContentScrollTop: (state, action) => {
      state.contentScrollTop = action.payload;
    },
    setIsFormEdited: (state, action) => {
      state.isFormEdited = action.payload;
    },
    setBackupOrganizationId: (state, action) => {
      state.backupOrganizationId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setScrollTop,
  setIsProcessing,
  setContentScrollTop,
  setIsFormEdited,
  setBackupOrganizationId,
} = commonSlice.actions;

export default commonSlice.reducer;
