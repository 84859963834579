const SYSTEM_PERMISSIONS = {
  master: 'master',
  organization: 'organization',
  shop: 'shop',
  wedding: 'wedding',
} as const;

const SYSTEM_PERMISSIONS_NAMES: Record<
  keyof typeof SYSTEM_PERMISSIONS,
  string
> = {
  [SYSTEM_PERMISSIONS.master]: '事務局',
  [SYSTEM_PERMISSIONS.organization]: 'チェーン',
  [SYSTEM_PERMISSIONS.shop]: '店舗',
  [SYSTEM_PERMISSIONS.wedding]: '企画',
};

export { SYSTEM_PERMISSIONS, SYSTEM_PERMISSIONS_NAMES };
