export default {
  Auth: {
    region: import.meta.env.VITE_USER_POOL_REGION,
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      domain: ' ', // Use a single space " " for host-only cookies
      expires: null, // null means session cookies
      path: '/',
      secure: !import.meta.env.DEV, // for developing on localhost over http: set to false
      sameSite: 'lax',
    },
  },
} as const;
