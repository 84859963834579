type IdDef = number | string | null | undefined;

const END_POINT = {
  // upload image to S3
  images: '/images',
  // API
  banners: '/banners',
  meOrganizations: '/me/organizations',
  bannerDetail: (id: IdDef) => `/banners/${id}`,
  drinks: '/drinks',
  drinkDetail: (id: IdDef) => `/drinks/${id}`,
  labels: '/label-sets',
  labelsDraft: '/label-sets/draft',
  labelDetail: (id: IdDef) => `/label-sets/${id}`,
  labelDetailDraft: (id: IdDef) => `/label-sets/${id}/draft`,
  originalLabelDetail: (id: IdDef) => `/full-customizes/shops/${id}/labels`,
  subImages: '/sub-images',
  shops: '/shops',
  shopsDetail: (id: IdDef) => `/shops/${id}`,
  shopsBusinessApplication: (id: IdDef) => `/application-forms/shops/${id}`,
  shopsBusinessApplicationDraft: (id: IdDef) =>
    `/application-forms/shops/${id}/draft`,
  shopsBusinessApplicationSecond: (id: IdDef) =>
    `/organizations/${id}/temporary-shops`,
  shopsBusinessApplicationSecondDraft: (id: IdDef) =>
    `/organizations/${id}/temporary-shops/draft`,
  shopDrinks: (id: IdDef) => `/shops/${id}/drinks`,
  shopsRegenerateQR: (id: IdDef) => `shops/${id}/qr-codes`,
  organizations: '/organizations',
  organizationsDetail: (id: IdDef) => `/organizations/${id}`,
  organizationShops: (id: IdDef) => `/organizations/${id}/shops`,
  organizationShopsWedding: (id: IdDef) => `/organizations/${id}/wedding-shops`,
  accounts: '/accounts',
  accountDetail: (id: IdDef) => `/accounts/${id}`,
  accountsResetPassword: (id: IdDef) => `/accounts/${id}/reset-password`,
  accountsForgotPassword: '/accounts/forgot-password',
  accountsConfirmForgotPassword: '/accounts/confirm-forgot-password',
  accountsRegistrationPasswordVerify: '/accounts/password-registration-verify',
  accountsConfirmRegistrationPassword: '/accounts/password-registration',
  accountsForgotPasswordVerify: '/accounts/forgot-password-verify',
  accountsResendConfirmAccount: (id: IdDef) =>
    `/accounts/${id}/resend-confirmation-code`,
  designTemplates: '/design-templates',
  snapDrinkDesignTemplates: '/snap-drink-templates',
  designTemplatesDetail: (id: IdDef) => `/design-templates/${id}`,
  shopLabelSets: (id: IdDef) => `/shops/${id}/label-sets`,
  shopsResetPassword: (id: IdDef) => `/shops/${id}/send-temporary-password`,
  salesPerformanceDetail: '/sale-figures',
  salesPerformanceUpdate: (id: IdDef) => `/sale-figure-shops/${id}`,
  salesPerformanceTemporarySaving: (id: IdDef) =>
    `/sale-figure-shops/${id}/temporary-save`,
  salesPerformanceSubmit: (id: IdDef) => `/sale-figures/${id}/submit`,
  salesPerformanceWithdrawal: (id: IdDef) => `/sale-figures/${id}/withdrawal`,
  salesPerformanceSendBack: (id: IdDef) => `/sale-figures/${id}/send-back`,
  salesPerformanceSkipRegistration: (id: IdDef) =>
    `/sale-figures/${id}/skip-registration`,
  amountAdjustmentDetail: (id: IdDef) => `/billing-adjustments/${id}`,
  amountAdjustmentUpdate: (id: IdDef) =>
    `/sale-figures/${id}/billing-adjustments`,
  paymentInformation: '/invoice-managements',
  paymentInformationDetail: '/sale-figure-managements',
  salesPerformanceFiscalMonth: '/fiscal-months',
  salesPerformanceFiscalMonthClosingCompleted: (id: IdDef) =>
    `fiscal-months/${id}/monthly-closing-completed`,
  salesPerformanceFiscalMonthApproveAll: (id: IdDef) =>
    `fiscal-months/${id}/registration-completed`,
  salesPerformanceFiscalMonthOutputCSV: (id: IdDef) =>
    `fiscal-months/${id}/csv-output`,
  salesPerformanceFiscalMonthOutputCSVAt: (id: IdDef) =>
    `fiscal-months/${id}/csv-output-at`,
  salesPerformanceHistory: '/fiscal-months/histories',
  invoiceStatus: '/invoice-status',
  originalLabelShops: '/full-customizes/shops',
  labelsFullCustom: '/full-ips',
  labelsFullCustomDraft: '/full-ips/draft',
  labelsFullCustomDetail: (id: IdDef) => `/full-ips/${id}`,
  labelsFullCustomDetailDraft: (id: IdDef) => `/full-ips/${id}/draft`,
} as const;

const API_STATUS_CODE = {
  success: 200,
  movedPermanently: 301,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  downloadFail: 406,
  outdated: 426,
  serverError: 500,
  timeout: 'ECONNABORTED',
} as const;

const API_ERROR_MESSAGES = {
  noInternet:
    'サーバー側に接続できません。通信環境を確認の上、再度お試しください。',
  [API_STATUS_CODE.unauthorized]: 'ユーザー認証に失敗しました。',
  [API_STATUS_CODE.forbidden]: 'アクセスできません。',
  [API_STATUS_CODE.notFound]: 'ページが見つかりません。',
  [API_STATUS_CODE.serverError]: 'サーバーエラーが発生しました。',
  [API_STATUS_CODE.downloadFail]: 'ダウンロードが失敗しました。',
  [API_STATUS_CODE.timeout]:
    '時間がかかりすぎたため、接続が中断されました。再度お試しください。',
  [API_STATUS_CODE.outdated]:
    'キャッシュクリアのため、画面をリロードしてください',
} as Record<string | number, string>;

export { API_ERROR_MESSAGES, API_STATUS_CODE, END_POINT };
