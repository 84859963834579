import { rest } from 'msw';

import { ShopItemDef } from '@app/models/shop.types';

import shop from './shop.json';

export const shopListGetHandler = rest.get<ShopItemDef>(
  `${import.meta.env.VITE_API_HOST}shops`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(shop));
  }
);

export default shopListGetHandler;
