import { rest } from 'msw';

import { BannerItemDef } from '@app/models/banner.types';

import banner from './banner.json';

export const bannerListGetHandler = rest.get<BannerItemDef>(
  `${import.meta.env.VITE_API_HOST}banner`,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(banner));
  }
);

export default bannerListGetHandler;
