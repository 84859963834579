import { RuleObject } from 'antd/lib/form';

import { LABEL_PREVIEW_MESSAGES } from './tagfont.constants';

type ValidationErrorLabel = 'overMaxLength' | 'notMatchRule';

type FormValidateRequiredDef = {
  required?: boolean;
  message?: string;
};

const FORM_ITEM_LAYOUT = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
} as const;

const FORM_ITEM_LAYOUT_SMALL_LABEL = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
} as const;

const FORM_ITEM_LAYOUT_FULL_LABEL = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
} as const;

const FORM_ITEM_LAYOUT_FULL_LABEL_WITH_PICKER = {
  labelCol: { span: 24 },
  wrapperCol: { span: 12 },
} as const;

const FORM_ITEM_LAYOUT_WITH_PICKER = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
} as const;

const FORM_ITEM_LAYOUT_WITH_LARGE_PICKER = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
} as const;

const FORM_ITEM_LAYOUT_WITH_PICKER_HAS_CHECKBOX = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
} as const;

const FORM_ITEM_LAYOUT_WITH_LONG_LABEL = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
} as const;

const FORM_ITEM_LAYOUT_SMALL_INPUT = {
  labelCol: { span: 5 },
  wrapperCol: { span: 4 },
} as const;

const FORM_ITEM_LAYOUT_PASSWORD = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
} as const;

const FORM_ITEM_LAYOUT_WITH_MEDIUM_LABEL = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
} as const;

const FORM_ITEM_LAYOUT_WITH_MEDIUM_LABEL_DATE = {
  labelCol: { span: 6 },
  wrapperCol: { span: 7 },
} as const;

const FORM_VALIDATION_RULES = {
  required: (options?: FormValidateRequiredDef | string) => {
    if (!options) return { required: true };
    if (typeof options === 'string')
      return { required: true, message: options };
    return { required: options.required, message: options.message };
  },
  whitespace: (message?: string) => ({ whitespace: true, message }),
  min: (min: number, message?: string) => ({ min, message }),
  max: (max: number, message?: string) => ({
    validator(_: RuleObject, value: string) {
      if (value?.length > max) return Promise.reject(message);
      return Promise.resolve();
    },
  }),
  len: (len: number, message?: string) => ({ len, message }),
  pattern: (pattern: RegExp, message?: string) => ({ pattern, message }),
  customizeLabelInput: (key: string) => ({
    validator() {
      const currentLabelInputValidation = JSON.parse(
        document.body.getAttribute('data-validation') || '{}'
      );
      if (currentLabelInputValidation[key]) {
        return Promise.reject(
          new Error(
            LABEL_PREVIEW_MESSAGES[
              currentLabelInputValidation[key] as ValidationErrorLabel
            ]
          )
        );
      }
      return Promise.resolve();
    },
  }),
} as const;

export {
  FORM_ITEM_LAYOUT,
  FORM_ITEM_LAYOUT_SMALL_LABEL,
  FORM_ITEM_LAYOUT_WITH_PICKER,
  FORM_ITEM_LAYOUT_WITH_LARGE_PICKER,
  FORM_ITEM_LAYOUT_WITH_PICKER_HAS_CHECKBOX,
  FORM_ITEM_LAYOUT_WITH_LONG_LABEL,
  FORM_ITEM_LAYOUT_FULL_LABEL,
  FORM_ITEM_LAYOUT_FULL_LABEL_WITH_PICKER,
  FORM_ITEM_LAYOUT_SMALL_INPUT,
  FORM_VALIDATION_RULES,
  FORM_ITEM_LAYOUT_PASSWORD,
  FORM_ITEM_LAYOUT_WITH_MEDIUM_LABEL,
  FORM_ITEM_LAYOUT_WITH_MEDIUM_LABEL_DATE,
};
