import { ReactComponent as IconRegistered } from '@app/assets/icons/icon-checked.svg';
import { ReactComponent as IconSendBack } from '@app/assets/icons/sales-performance/icon-send-back.svg';
import { ReactComponent as IconSkip } from '@app/assets/icons/sales-performance/icon-skip.svg';
import { ReactComponent as IconSubmitting } from '@app/assets/icons/sales-performance/icon-submitting.svg';
import { ReactComponent as IconUnreported } from '@app/assets/icons/sales-performance/icon-unreported.svg';

// figure shop registration status
const SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS = {
  entered: 'entered',
  'not-entered': 'not-entered',
  'temporary-saving': 'temporary-saving',
} as const;

const SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS_TEXT = {
  [SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS.entered]: '入力済み',
  [SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS['not-entered']]: '未入力',
  [SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS['temporary-saving']]:
    '一時保存',
} as const;

const SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS_BADGE = {
  [SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS.entered]: 'success',
  [SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS['not-entered']]: 'error',
  [SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS['temporary-saving']]:
    'warning',
} as const;

// service using constants
const SALES_PERFORMANCE_SERVICE_USING_KEY = {
  // organization
  contracting: 'contracting',
  'out-of-contract': 'out-of-contract',
  // shop
  'before-use': 'before-use',
  'in-use': 'in-use',
  suspended: 'suspended',
};

const SALES_PERFORMANCE_SERVICE_USING = {
  [SALES_PERFORMANCE_SERVICE_USING_KEY['contracting']]: '契約中',
  [SALES_PERFORMANCE_SERVICE_USING_KEY['out-of-contract']]: '契約外',
  [SALES_PERFORMANCE_SERVICE_USING_KEY['before-use']]: '利用開始前',
  [SALES_PERFORMANCE_SERVICE_USING_KEY['in-use']]: '利用中',
  [SALES_PERFORMANCE_SERVICE_USING_KEY['suspended']]: '利用停止中',
} as const;

// figure registration status
const SALES_PERFORMANCE_FIGURES_STATUS = {
  'registration-completed': 'registration-completed',
  'monthly-closing-completed': 'monthly-closing-completed',
  'skip-registration': 'skip-registration',
  unreported: 'unreported',
  submitting: 'submitting',
  'send-back': 'send-back',
  // figures shop tag status
  'figure-shop-send-back': 'figure-shop-send-back',
  'difference-between-printed-quantity-and-sold-quantity':
    'difference-between-printed-quantity-and-sold-quantity',
} as const;

// history csv status constants
const SALES_PERFORMANCE_CSV_OUTPUT_STATUS_KEY = {
  'not-yet-output': 'not-yet-output',
  'already-output': 'already-output',
  'unable-to-output': 'unable-to-output',
} as const;

const SALES_PERFORMANCE_TAG_CONTENT = {
  [SALES_PERFORMANCE_FIGURES_STATUS.unreported]: {
    text: '未申請',
    icon: <IconUnreported color="#acacac" />,
  },
  [SALES_PERFORMANCE_FIGURES_STATUS['send-back']]: {
    text: '差戻し',
    icon: <IconSendBack />,
    type: SALES_PERFORMANCE_FIGURES_STATUS['send-back'],
  },
  [SALES_PERFORMANCE_FIGURES_STATUS['skip-registration']]: {
    text: '翌月繰越し',
    icon: <IconSkip color="#acacac" />,
    type: SALES_PERFORMANCE_FIGURES_STATUS['skip-registration'],
  },
  [SALES_PERFORMANCE_FIGURES_STATUS['registration-completed']]: {
    text: '申請完了',
    icon: <IconRegistered />,
    type: SALES_PERFORMANCE_FIGURES_STATUS['registration-completed'],
  },
  [SALES_PERFORMANCE_FIGURES_STATUS.submitting]: {
    text: '申請中',
    icon: <IconSubmitting />,
  },
  [SALES_PERFORMANCE_FIGURES_STATUS['monthly-closing-completed']]: {
    text: '請求処理完了',
    type: SALES_PERFORMANCE_FIGURES_STATUS['monthly-closing-completed'],
  },
  [SALES_PERFORMANCE_FIGURES_STATUS['figure-shop-send-back']]: {
    text: '差戻し対象',
    icon: <IconSendBack color="#F43636" />,
    type: SALES_PERFORMANCE_FIGURES_STATUS['figure-shop-send-back'],
  },
  [SALES_PERFORMANCE_FIGURES_STATUS[
    'difference-between-printed-quantity-and-sold-quantity'
  ]]: {
    text: '差分あり',
    icon: <IconUnreported color="#FAAD14" />,
    type: SALES_PERFORMANCE_FIGURES_STATUS[
      'difference-between-printed-quantity-and-sold-quantity'
    ],
  },
} as const;

const SALES_PERFORMANCE_FIGURE_SHOP_EMPTY_ITEM = {
  price_unit: null,
  sales_quantity: null,
} as const;

const AMOUNT_ADJUSTMENT_EMPTY_ITEM = {
  description: '',
  amount: null,
} as const;

const SYSTEM_FEE_PERCENT = 20 as const;

export {
  SALES_PERFORMANCE_SERVICE_USING,
  SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS_BADGE,
  SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS_TEXT,
  SALES_PERFORMANCE_FIGURES_REGISTRATION_STATUS,
  SALES_PERFORMANCE_FIGURES_STATUS,
  SALES_PERFORMANCE_TAG_CONTENT,
  SALES_PERFORMANCE_CSV_OUTPUT_STATUS_KEY,
  SALES_PERFORMANCE_FIGURE_SHOP_EMPTY_ITEM,
  SYSTEM_FEE_PERCENT,
  AMOUNT_ADJUSTMENT_EMPTY_ITEM,
};
